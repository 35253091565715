"use client";

import Image from "next/image";
import Link from "next/link";
import { roomsData } from "@/data/chambres";
import ReservationButton from "@/components/buttons/ReservationButton";
import { useI18n } from "@/i18n/client";
import { getLocalizedHref } from "@/utils/localization";

export const RoomsSection = () => {
  const { t, locale } = useI18n();

  return (
    <section
      id="chambres"
      className="flex flex-col gap-16 bg-secondary px-4 py-12 text-white ph:px-24 md:px-48 lg:px-72 xl:px-96"
    >
      {/* Titre de la section */}
      <div className="flex flex-col items-start gap-4 md:items-center">
        <h2 className="text-3xl font-light tracking-wider xs:text-4xl ph:text-5xl">
          {t("rooms.title")}
        </h2>
        <p className="text-left text-xl md:text-center">
          {t("rooms.subtitle")}
        </p>
      </div>

      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-1 gap-10 lg:grid-cols-2 lg:gap-16 xl:gap-20">
          {roomsData.map((room) => (
            <div key={room.id} className="flex flex-col overflow-hidden">
              {/* Image de la chambre */}
              <div className="relative h-64 w-full overflow-hidden rounded-2xl lg:h-[24rem] xl:h-[28rem]">
                <Link href={getLocalizedHref(`/chambres/${room.id}`, locale)}>
                  <Image
                    src={room.photos[0]}
                    alt={`${locale === "fr" ? "Chambre" : "Room"} ${room.name}`}
                    fill
                    className="cursor-pointer object-cover transition-transform duration-700 hover:scale-105"
                  />
                </Link>
              </div>

              <div className="flex flex-col gap-2 pt-4 lg:pt-6">
                <h3 className="text-2xl lg:text-3xl">{room.name}</h3>
                <p className="text-xl font-light">
                  {t(room.shortDescriptionKey as any)}
                </p>
                <div className="mt-1 flex flex-wrap gap-6">
                  <span className="font-garamond text-lg">
                    {room.nbPerson.min === room.nbPerson.max
                      ? `${room.nbPerson.max} ${room.nbPerson.max > 1 ? t("rooms.persons") : t("rooms.person")}`
                      : `${room.nbPerson.min} ${t("rooms.to")} ${room.nbPerson.max} ${t("rooms.persons")}`}
                  </span>
                  <span className="font-garamond text-lg">
                    {t("rooms.startingFrom")} {room.prices[0].price}€
                  </span>
                </div>
                <div className="mt-2 flex gap-4">
                  <Link
                    href={getLocalizedHref(`/chambres/${room.id}`, locale)}
                    className="inline-block border-b border-white pb-1 text-xl text-white transition-all hover:border-black hover:text-black"
                  >
                    {t("rooms.discover")}
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-12 flex flex-col items-center justify-center gap-4 sm:flex-row">
          <Link
            href="https://www.gites-de-france.com/fr/normandie/seine-maritime/les-chambres-dhotes-danne-76g24024"
            target="_blank"
            className="mt-4 rounded-full bg-primary px-6 py-3 text-xl font-medium uppercase text-secondary shadow-md transition-all hover:bg-accent hover:shadow-lg"
          >
            {t("cta.bookNow")}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default RoomsSection;
