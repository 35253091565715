import { StaticImageData } from "next/image";

// chambres pivoine

import pivoine1 from "@/assets/images/chambres/pivoine/chambre-pivoine-1.jpg";
import pivoine2 from "@/assets/images/chambres/pivoine/chambre-pivoine-2.jpg";

// chambre orchidée

import orchidee1 from "@/assets/images/chambres/moisson/chambre-moisson-1.jpg";

// chambre bois doré

import boisDore1 from "@/assets/images/chambres/bois-dore/bois-dore-1.jpg";
import boisDore2 from "@/assets/images/chambres/bois-dore/bois-dore-2.jpg";

// chambre camelia

import camelia1 from "@/assets/images/chambres/camelia/chambre-camelia1.jpg";
import camelia2 from "@/assets/images/chambres/camelia/chambre-camelia-2.jpg";

export interface Price {
  persons: number | string;
  price: number;
  descriptionKey?: string;
}

export interface Room {
  id: string;
  name: string;
  roomNumber: string;
  nbPerson: {
    min: number;
    max: number;
  };
  prices: Price[];
  additionalFees: Price[];
  shortDescriptionKey: string;
  longDescriptionKey: string;
  photos: StaticImageData[];
}

export const roomsData: Room[] = [
  {
    id: "camelia",
    name: "Camélia",
    roomNumber: "1",
    nbPerson: {
      min: 1,
      max: 2,
    },
    prices: [
      { persons: 1, price: 50.0 },
      { persons: 2, price: 62.0 },
    ],
    additionalFees: [
      {
        persons: "taxe-sejour",
        price: 0.6,
        descriptionKey: "fee.taxe-sejour",
      },
      {
        persons: "assiette-gourmande",
        price: 20.0,
        descriptionKey: "fee.assiette-gourmande",
      },
    ],
    shortDescriptionKey: "room.camelia.short",
    longDescriptionKey: "room.camelia.long",
    photos: [camelia1, camelia2],
  },
  {
    id: "bois-dore",
    name: "Bois doré",
    roomNumber: "2",
    nbPerson: {
      min: 1,
      max: 2,
    },
    prices: [
      { persons: 1, price: 55.0 },
      { persons: 2, price: 65.0 },
    ],
    additionalFees: [
      {
        persons: "taxe-sejour",
        price: 0.6,
        descriptionKey: "fee.taxe-sejour",
      },
      {
        persons: "assiette-gourmande",
        price: 20.0,
        descriptionKey: "fee.assiette-gourmande",
      },
    ],
    shortDescriptionKey: "room.bois-dore.short",
    longDescriptionKey: "room.bois-dore.long",
    photos: [boisDore2, boisDore1],
  },
  {
    id: "orchidee",
    name: "Orchidée",
    roomNumber: "3",
    nbPerson: {
      min: 1,
      max: 3,
    },
    prices: [
      { persons: 1, price: 55.0 },
      { persons: 2, price: 70.0 },
      {
        persons: "personne-supplementaire",
        price: 25.0,
        descriptionKey: "fee.personne-supplementaire",
      },
      {
        persons: "lit-enfant",
        price: 15.0,
        descriptionKey: "fee.lit-enfant",
      },
    ],
    additionalFees: [
      {
        persons: "taxe-sejour",
        price: 0.6,
        descriptionKey: "fee.taxe-sejour",
      },
    ],
    shortDescriptionKey: "room.orchidee.short",
    longDescriptionKey: "room.orchidee.long",
    photos: [orchidee1],
  },
  {
    id: "pivoine",
    name: "Pivoine",
    roomNumber: "4",
    nbPerson: {
      min: 2,
      max: 4,
    },
    prices: [
      { persons: 2, price: 80.0 },
      { persons: 3, price: 100.0 },
      { persons: 4, price: 120.0 },
      {
        persons: "personne-supplementaire",
        price: 25.0,
        descriptionKey: "fee.personne-supplementaire",
      },
    ],
    additionalFees: [
      {
        persons: "taxe-sejour",
        price: 0.6,
        descriptionKey: "fee.taxe-sejour",
      },
    ],
    shortDescriptionKey: "room.pivoine.short",
    longDescriptionKey: "room.pivoine.long",
    photos: [pivoine1, pivoine2],
  },
];

export default roomsData;
