"use client";

import Image from "next/image";
import extra from "@/assets/images/homepage/extra.jpg";
import Link from "next/link";
import { useI18n } from "@/i18n/client";

export const ExtraSection = () => {
  const { t, locale } = useI18n();

  return (
    <section className="flex flex-col justify-between px-4 py-20 ph:px-24 md:px-48 lg:flex-row lg:gap-12 lg:px-72 xl:px-96">
      {/* Image à gauche */}
      <div className="mb-10 lg:mb-0 lg:w-1/2">
        <Image
          src={extra}
          alt={t("extra.imageAlt")}
          className="h-auto w-full rounded-2xl object-cover lg:h-[620px] xl:h-[480px]"
        />
      </div>

      {/* Contenu textuel à droite */}
      <div className="flex flex-col gap-6 lg:w-1/2">
        <h2 className="mb-6 text-3xl font-light tracking-wider xs:text-4xl ph:text-5xl">
          {t("extra.title")}
        </h2>
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-2 text-xl">
            <p>{t("extra.paragraph1")}</p>
            <p>{t("extra.paragraph2")}</p>
          </div>
          <div>
            <Link
              href="https://www.gites-de-france.com/fr/normandie/seine-maritime/les-chambres-dhotes-danne-76g24024"
              target="_blank"
              className="rounded-full bg-secondary px-4 py-2 text-lg font-medium uppercase text-white shadow-md transition-all hover:bg-[#3d6649] hover:shadow-lg md:px-6 md:text-xl"
            >
              {t("extra.cta")}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};
