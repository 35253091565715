"use client";

import Image from "next/image";
import { useState, useEffect, useRef } from "react";
import { useI18n } from "@/i18n/client";

import place1 from "@/assets/images/place/place-1.jpg";
import place3 from "@/assets/images/place/place-3.jpg";
import place4 from "@/assets/images/place/place-4.jpg";
import place5 from "@/assets/images/place/place-5.jpg";
import place6 from "@/assets/images/place/place-6.jpg";
import place7 from "@/assets/images/place/place-7.jpg";
import place8 from "@/assets/images/place/place-8.jpg";

export const PlaceSection = () => {
  const { t, locale } = useI18n();
  const [activeImage, setActiveImage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);
  const autoPlayIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const imagesPerPage = 6; // Nombre d'images par page

  // Images pour la galerie avec traductions
  const placeImages = [
    {
      src: place1,
      alt:
        locale === "fr"
          ? "Jardin fleuri et bâtiments des Chambres d'Anne"
          : "Flowery garden and buildings of Anne's Rooms",
      caption: t("place.caption1"),
    },
    {
      src: place3,
      alt:
        locale === "fr"
          ? "Belle bâtisse ornée de fleurs"
          : "Beautiful mansion adorned with flowers",
      caption: t("place.caption3"),
    },
    {
      src: place4,
      alt:
        locale === "fr"
          ? "Jardin fleuri et potager"
          : "Flowering garden and vegetable patch",
      caption: t("place.caption4"),
    },
    {
      src: place5,
      alt:
        locale === "fr"
          ? "Vue de la bâtisse et du jardin"
          : "View of the mansion and garden",
      caption: t("place.caption5"),
    },
    {
      src: place6,
      alt:
        locale === "fr"
          ? "Vue panoramique avec le clocher du village"
          : "Panoramic view with the village church spire",
      caption: t("place.caption6"),
    },
    {
      src: place7,
      alt:
        locale === "fr"
          ? "Paysage des champs environnants"
          : "Landscape of the surrounding fields",
      caption: t("place.caption7"),
    },
    {
      src: place8,
      alt:
        locale === "fr"
          ? "Poulailler avec poules, coq et canards"
          : "Chicken coop with hens, rooster and ducks",
      caption: t("place.caption8"),
    },
  ];

  const totalPages = Math.ceil(placeImages.length / imagesPerPage);

  // Fonction pour passer à l'image suivante avec retour au début si nécessaire
  const goToNextImage = () => {
    setActiveImage((prev) => (prev + 1) % placeImages.length);
    // Mettre à jour la page si nécessaire
    const nextImageIndex = (activeImage + 1) % placeImages.length;
    const nextImagePage = Math.floor(nextImageIndex / imagesPerPage);
    if (nextImagePage !== currentPage) {
      setCurrentPage(nextImagePage);
    }
  };

  // Défilement automatique
  useEffect(() => {
    if (autoPlay) {
      autoPlayIntervalRef.current = setInterval(goToNextImage, 4000);
    }

    // Nettoyage de l'intervalle lors du démontage du composant
    return () => {
      if (autoPlayIntervalRef.current) {
        clearInterval(autoPlayIntervalRef.current);
        autoPlayIntervalRef.current = null;
      }
    };
  }, [autoPlay, activeImage, currentPage]);

  // Pause du défilement automatique lors du clic manuel
  const handleImageClick = (index: number) => {
    setActiveImage(index);
    // Mettre en pause brièvement le défilement automatique après un clic
    setAutoPlay(false);
    // Reprendre après 10 secondes d'inactivité
    setTimeout(() => setAutoPlay(true), 10000);
  };

  // Calculer les images à afficher sur la page actuelle
  const displayedImages = placeImages.slice(
    currentPage * imagesPerPage,
    (currentPage + 1) * imagesPerPage,
  );

  // Fonction pour changer de page
  const changePage = (newPage: number) => {
    if (newPage >= 0 && newPage < totalPages) {
      setCurrentPage(newPage);
      // Pause brève du défilement automatique lors du changement de page
      setAutoPlay(false);
      setTimeout(() => setAutoPlay(true), 10000);
    }
  };

  return (
    <section
      className="flex flex-col gap-16 px-4 py-16 ph:px-24 md:px-48 lg:px-72 xl:px-96"
      id="le-lieu"
    >
      {/* Titre de la section */}
      <div className="flex flex-col items-start gap-2 text-center md:items-center">
        <h2 className="text-3xl font-light tracking-wider xs:text-4xl ph:text-5xl">
          {t("place.title")}
        </h2>
        <p className="text-left text-xl font-light md:text-center">
          {t("place.subtitle")}
        </p>
      </div>

      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-8">
          <div className="relative h-[40vh] w-full overflow-hidden rounded-2xl md:h-[60vh] lg:h-[70vh]">
            <Image
              src={placeImages[activeImage].src}
              alt={placeImages[activeImage].alt}
              fill
              className="object-cover transition-all duration-700"
              priority
            />
            <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/60 to-transparent p-6 text-white">
              <p className="text-xl font-light italic">
                {placeImages[activeImage].caption}
              </p>
            </div>
          </div>

          {/* Navigation des miniatures avec pagination */}
          <div className="mx-auto flex max-w-md flex-col items-center gap-3">
            {/* Miniatures */}
            <div className="flex flex-wrap justify-center gap-2">
              {displayedImages.map((image, index) => {
                const actualIndex = currentPage * imagesPerPage + index;
                return (
                  <button
                    key={actualIndex}
                    onClick={() => handleImageClick(actualIndex)}
                    className={`relative h-12 w-12 overflow-hidden rounded-md transition-all duration-300 md:h-10 md:w-10 ${
                      activeImage === actualIndex
                        ? "border-2 border-[#4A7C59] p-[2px]"
                        : "opacity-70 hover:opacity-100"
                    }`}
                  >
                    <Image
                      src={image.src}
                      alt={`${t("place.thumbnail")} ${actualIndex + 1}`}
                      fill
                      className="object-cover"
                    />
                  </button>
                );
              })}
            </div>

            {/* Pagination (seulement si plus d'une page) */}
            {totalPages > 1 && (
              <div className="text-md flex items-center gap-2 text-[#4A7C59]">
                <button
                  onClick={() => changePage(currentPage - 1)}
                  disabled={currentPage === 0}
                  className={`flex h-6 w-6 items-center justify-center rounded-full ${
                    currentPage === 0 ? "opacity-30" : "hover:bg-gray-100"
                  }`}
                  aria-label={t("place.previousPage")}
                >
                  ←
                </button>

                <span className="text-md">
                  {currentPage + 1}/{totalPages}
                </span>

                <button
                  onClick={() => changePage(currentPage + 1)}
                  disabled={currentPage === totalPages - 1}
                  className={`flex h-6 w-6 items-center justify-center rounded-full ${
                    currentPage === totalPages - 1
                      ? "opacity-30"
                      : "hover:bg-gray-100"
                  }`}
                  aria-label={t("place.nextPage")}
                >
                  →
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Description élégante */}
        <div className="mx-auto max-w-3xl text-center">
          <p className="text-lg font-light leading-relaxed">
            {t("place.description")}
          </p>
        </div>
      </div>
    </section>
  );
};

export default PlaceSection;
