import benedicteGiron from "@/assets/images/reviews/benedicte-giron.png";
import danielHarlet from "@/assets/images/reviews/daniel-harlet.png";
import guffroyYann from "@/assets/images/reviews/guffroy-yann.png";
import raphaelBoulos from "@/assets/images/reviews/raphael-boulos.png";
import stefaneGiorgetti from "@/assets/images/reviews/stephane-giorgetti.png";
import kevinPreumont from "@/assets/images/reviews/kevin-preumont.png";

export interface Review {
  name: string;
  stars: number;
  quote: string;
  quoteEn: string;
  image: any;
}

export const reviews: Review[] = [
  {
    quote:
      "Un petit mot pour un grand merci à notre sympathique hôte dont on ressent le naturel de sa gentillesse et de sa disponibilité. Nous avons eu le temps pendant notre séjour de profiter des bons petits-déjeuners avec crêpes, confitures, yaourts et dîners...",
    quoteEn:
      "A small note for a big thank you to our friendly host whose natural kindness and availability we feel. We had time during our stay to enjoy the good breakfasts with pancakes, jams, yogurts and dinners...",
    name: "Benedicte G.",
    stars: 5,
    image: benedicteGiron,
  },
  {
    quote:
      "Des chambres spacieuses et propres au calme, un repas simple, copieux, et très bon, le petit déjeuner au poil pour démarrer la journée, Anne vous reservera un bon accueil et une disponibilité.",
    quoteEn:
      "Spacious and clean rooms in a quiet area, a simple, hearty, and very good meal, the perfect breakfast to start the day, Anne will give you a warm welcome and availability.",
    name: "Daniel H.",
    stars: 5,
    image: danielHarlet,
  },
  {
    quote:
      "Très bonne adresse, réception hyper accueillante et serviable. Les chambres sont propres et charmantes. Mention spéciale pour le petit déjeuner varié, fourni et pleins de produits locaux. À recommander les yeux fermés. Merci encore.",
    quoteEn:
      "Very good place, super welcoming and helpful reception. The rooms are clean and charming. Special mention for the varied breakfast, well-stocked and full of local products. Highly recommended. Thank you again.",
    name: "Raphaël B.",
    stars: 5,
    image: raphaelBoulos,
  },
  {
    quote:
      "Nous avons passé 5 nuits dans ce gîte pour Noël. C était tout simplement sublime. L accueil d Anne, la propriétaire des lieux, est plus que chaleureux et instantanément elle vous fera vous sentir comme chez vous. La literie de notre chambre était très bonne...",
    quoteEn:
      "We spent 5 nights in this guesthouse for Christmas. It was simply sublime. The welcome from Anne, the owner, is more than warm and she will instantly make you feel at home. The bedding in our room was very good...",
    name: "Stephane G.",
    stars: 5,
    image: stefaneGiorgetti,
  },
  {
    quote:
      "Tout ce que vous recherchez dans une chambre / table d'hôte, vous le trouverez ici. Diner et petits déjeuners très ben fournis, de qualité, bon rapport qualité/prix Merci à Anne pour son dévouement et son sens du service",
    quoteEn:
      "Everything you're looking for in a bed and breakfast, you'll find it here. Dinner and breakfasts very well provided, quality, good value for money. Thank you to Anne for her dedication and sense of service",
    name: "Guffroy Y.",
    stars: 5,
    image: guffroyYann,
  },
  {
    quote:
      "Accueil excellent, nous avons des très chouettes échanges avec Anne. La maison est dans un très beau cadre, les enfants se sont sentis chez eux. Il y a un âne et des poules, un trampoline, de quoi occuper les petits. La literie est super, nous avions...",
    quoteEn:
      "Excellent welcome, we had very nice exchanges with Anne. The house is in a beautiful setting, the children felt at home. There is a donkey and chickens, a trampoline, enough to keep the little ones busy. The bedding is great, we had...",
    name: "Kevin P.",
    stars: 5,
    image: kevinPreumont,
  },
];
