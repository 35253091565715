"use client";

import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@nextui-org/react";
import { useRouter } from "next/navigation";
import { Button } from "@nextui-org/react";
import { useI18n } from "@/i18n/client";

// Schéma de validation
const contactSchema = z.object({
  firstName: z
    .string()
    .min(2, "Le prénom doit contenir au moins 2 caractères")
    .max(50, "Le prénom ne doit pas dépasser 50 caractères")
    .regex(/^[a-zA-ZÀ-ÿ\s-]+$/, "Le prénom ne doit contenir que des lettres"),
  lastName: z
    .string()
    .min(2, "Le nom doit contenir au moins 2 caractères")
    .max(50, "Le nom ne doit pas dépasser 50 caractères")
    .regex(/^[a-zA-ZÀ-ÿ\s-]+$/, "Le nom ne doit contenir que des lettres"),
  phone: z
    .string()
    .regex(
      /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/,
      "Numéro de téléphone invalide",
    ),
  email: z
    .string()
    .email("Adresse email invalide")
    .min(5, "L'email doit contenir au moins 5 caractères")
    .max(100, "L'email ne doit pas dépasser 100 caractères"),
  message: z
    .string()
    .max(1000, "Votre message ne doit pas dépasser 1000 caractères"),
  honeypot: z.string().max(0, "Champ invalide"),
});

type ContactFormData = z.infer<typeof contactSchema>;

export const ContactSection = () => {
  const router = useRouter();
  const { t } = useI18n();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ContactFormData>({
    resolver: zodResolver(contactSchema),
  });

  const handleCloseSuccess = () => {
    setShowSuccessModal(false);
    router.push("/");
  };

  const onSubmit = async (data: ContactFormData) => {
    try {
      setIsSubmitting(true);
      const response = await fetch("/api/contact", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...data,
          formType: "information",
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Erreur détaillée:", errorData);
        throw new Error(errorData.error || "Erreur lors de l'envoi");
      }

      reset();
      setShowSuccessModal(true);
    } catch (error) {
      console.error("Erreur complète:", error);
      alert(error instanceof Error ? error.message : "Une erreur est survenue");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <section
        className="flex flex-col justify-between bg-secondary px-4 py-20 ph:px-24 md:px-48 lg:px-72 xl:px-96"
        id="contact"
      >
        {/* Titre et sous-titre */}
        <div className="mb-10 flex flex-col items-start gap-2 text-white md:items-center">
          <h2 className="text-3xl font-light tracking-wider xs:text-4xl ph:text-5xl">
            Besoin d&apos;informations ?
          </h2>
          <p className="text-left text-xl font-light md:text-xl">
            Nous sommes à votre disposition pour répondre à toutes vos questions
          </p>
        </div>

        {/* Formulaire de contact */}
        <div className="mx-auto w-full max-w-3xl rounded-lg bg-white p-8 shadow-sm">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
            {/* Honeypot field */}
            <div className="hidden">
              <input
                type="text"
                {...register("honeypot")}
                tabIndex={-1}
                autoComplete="off"
              />
            </div>

            <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
              <div className="space-y-2">
                <label className="text-lg font-light text-gray-700">
                  Prénom
                </label>
                <input
                  {...register("firstName")}
                  className="w-full border-0 border-b border-gray-300 bg-transparent p-2 font-light text-gray-900 outline-none transition-all focus:border-secondary"
                  type="text"
                  placeholder="Votre prénom"
                />
                {errors.firstName && (
                  <p className="text-lg italic text-red-400">
                    {errors.firstName.message}
                  </p>
                )}
              </div>

              <div className="space-y-2">
                <label className="text-lg font-light text-gray-700">Nom</label>
                <input
                  {...register("lastName")}
                  className="w-full border-0 border-b border-gray-300 bg-transparent p-2 font-light text-gray-900 outline-none transition-all focus:border-secondary"
                  type="text"
                  placeholder="Votre nom"
                />
                {errors.lastName && (
                  <p className="text-lg italic text-red-400">
                    {errors.lastName.message}
                  </p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
              <div className="space-y-2">
                <label className="text-lg font-light text-gray-700">
                  Téléphone
                </label>
                <input
                  {...register("phone")}
                  className="w-full border-0 border-b border-gray-300 bg-transparent p-2 font-light text-gray-900 outline-none transition-all focus:border-secondary"
                  type="tel"
                  placeholder="Votre numéro de téléphone"
                />
                {errors.phone && (
                  <p className="text-lg italic text-red-400">
                    {errors.phone.message}
                  </p>
                )}
              </div>

              <div className="space-y-2">
                <label className="text-lg font-light text-gray-700">
                  Adresse e-mail
                </label>
                <input
                  {...register("email")}
                  className="w-full border-0 border-b border-gray-300 bg-transparent p-2 font-light text-gray-900 outline-none transition-all focus:border-secondary"
                  type="email"
                  placeholder="Votre adresse email"
                />
                {errors.email && (
                  <p className="text-lg italic text-red-400">
                    {errors.email.message}
                  </p>
                )}
              </div>
            </div>

            <div className="space-y-2">
              <label className="text-lg font-light text-gray-700">
                Votre message
              </label>
              <textarea
                {...register("message")}
                className="h-32 w-full resize-none border-0 border-b border-gray-300 bg-transparent p-2 font-light text-gray-900 outline-none transition-all focus:border-secondary"
                placeholder="Comment pouvons-nous vous aider ?"
              />
              {errors.message && (
                <p className="text-lg italic text-red-400">
                  {errors.message.message}
                </p>
              )}
            </div>

            <div className="pt-4">
              <button
                type="submit"
                disabled={isSubmitting}
                className="rounded-full bg-secondary px-6 py-2 text-lg font-medium text-white shadow-md transition-all hover:bg-[#3d6649] hover:shadow-lg disabled:opacity-50"
              >
                {isSubmitting ? "Envoi en cours..." : "Envoyer ma demande"}
              </button>
            </div>

            <div className="space-y-2 pt-2 text-center">
              <p className="text-md font-light text-gray-500">
                🔒 Vos données sont 100 % confidentielles et sécurisées
              </p>
              <p className="text-md font-light italic text-secondary">
                Nous vous répondrons dans les plus brefs délais
              </p>
            </div>
          </form>
        </div>
      </section>

      <Modal
        isOpen={showSuccessModal}
        onClose={handleCloseSuccess}
        onOpenChange={(open) => {
          if (!open) handleCloseSuccess();
        }}
        className="bg-primary dark:bg-gray-900"
      >
        <ModalContent className="bg-primary p-4 dark:bg-gray-900">
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 border-b border-gray-200 pb-4 text-center font-serif text-xl font-light text-gray-900 dark:text-white">
                Votre message a bien été envoyé
              </ModalHeader>
              <ModalBody className="py-6 text-center">
                <div className="mx-auto mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-secondary/20 text-secondary">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                    <polyline points="22 4 12 14.01 9 11.01"></polyline>
                  </svg>
                </div>
                <p className="text-lg font-light text-gray-800 dark:text-gray-200">
                  Nous avons bien reçu votre demande d&apos;informations. Notre
                  équipe vous contactera dans les plus brefs délais pour
                  répondre à toutes vos questions.
                </p>
              </ModalBody>
              <ModalFooter className="flex justify-center border-t border-gray-200 pt-4">
                <Button
                  onPress={onClose}
                  className="rounded-full bg-secondary px-6 py-2 text-lg font-light text-white transition-all hover:bg-[#3d6649]"
                >
                  Retourner à l&apos;accueil
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
