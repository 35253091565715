// use-scroll-restoration.ts est le fichier de configuration pour la restauration du scroll de votre application

"use client";
import { useEffect } from "react";

export default function ScrollRestoration() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      // Vérifie si l'URL contient un fragment (comme #reservation)
      const hash = window.location.hash;

      if (hash) {
        // Si un fragment est présent, on attend que le DOM soit complètement chargé
        setTimeout(() => {
          const element = document.getElementById(hash.substring(1));
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }, 100);
      } else {
        // Sinon, on restaure la position de défilement précédente
        const scrollPosition = sessionStorage.getItem("scrollPosition");
        if (scrollPosition) {
          window.scrollTo(0, parseInt(scrollPosition, 10));
          sessionStorage.removeItem("scrollPosition");
        }
      }
    }

    const handleBeforeUnload = () => {
      sessionStorage.setItem("scrollPosition", window.scrollY.toString());
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  return null;
}
