"use client";

import Image from "next/image";
import place1 from "@/assets/images/place/place-1.jpg";
import Link from "next/link";
import { useI18n } from "@/i18n/client";
import { getLocalizedHref } from "@/utils/localization";

import label from "@/assets/images/homepage/3-epis.png";

export const IntroSection = () => {
  const { t, locale } = useI18n();

  return (
    <section className="flex flex-col justify-between px-4 py-20 ph:px-24 md:px-48 lg:flex-row lg:gap-12 lg:px-72 xl:px-96">
      {/* Contenu textuel à gauche */}
      <div className="flex flex-col gap-6 lg:w-1/2">
        <h2 className="mb-6 text-3xl font-light tracking-wider xs:text-4xl ph:text-5xl">
          {t("intro.title")}
        </h2>
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-2 text-xl">
            {/* <p>{t("intro.paragraph1")}</p> */}
            {/* <p>{t("intro.paragraph2")}</p> */}
            <p>{t("intro.paragraph3")}</p>
          </div>
          <div>
            <Link
              href={getLocalizedHref("/#chambres", locale)}
              className="rounded-full bg-secondary px-4 py-2 text-lg font-medium uppercase text-white shadow-md transition-all hover:bg-[#3d6649] hover:shadow-lg md:px-6 md:text-xl"
            >
              {t("intro.cta")}
            </Link>
          </div>
        </div>
      </div>

      {/* Colonne droite avec image et label */}
      <div className="mt-10 flex flex-col lg:mt-0 lg:w-1/2">
        <Image
          src={place1}
          alt={
            locale === "fr"
              ? "Chambre d'hôtes en Seine Maritime"
              : "Guest house in Seine Maritime"
          }
          className="h-auto w-full rounded-2xl object-cover lg:h-[500px] xl:h-[450px]"
        />

        {/* Label 3 épis */}
        <div className="mt-4 flex items-center justify-end gap-3">
          <p className="text-lg font-medium text-gray-700">
            {locale === "fr"
              ? "Notre gîte est classé Label 3 épis"
              : "Our guesthouse is classified with 3 ears Label"}
          </p>
          <Image
            src={label}
            alt="Label 3 épis"
            width={70}
            height={70}
            className="object-contain"
          />
        </div>
      </div>
    </section>
  );
};
