import background from "@/assets/images/homepage/background2.jpg";
import { useEffect, useRef } from "react";
import gsap from "gsap";
import { SystemuiChevronDownCircle } from "@/assets/icons/systemui-chevron-down-circle";
import { useI18n } from "@/i18n/client";

export const HeroSection = () => {
  const heroRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const chevronRef = useRef<HTMLDivElement>(null);
  const { t } = useI18n();

  useEffect(() => {
    const tl = gsap.timeline({ defaults: { ease: "power3.out" } });

    // Animation initiale - tout est invisible
    gsap.set(heroRef.current, { opacity: 0, scale: 1.05 });
    gsap.set(overlayRef.current, { opacity: 0 });
    gsap.set(contentRef.current, { opacity: 0 });
    gsap.set(chevronRef.current, { opacity: 0, y: 20 });

    // Séquence d'animation
    tl.to(heroRef.current, {
      opacity: 1,
      scale: 1,
      duration: 1.8,
      delay: 0.2,
    })
      .to(
        overlayRef.current,
        {
          opacity: 0.3,
          duration: 1.2,
        },
        "-=1.4",
      )
      .to(contentRef.current, { opacity: 1, duration: 1 }, "-=0.8")
      .fromTo(contentRef.current, { y: 30 }, { y: 0, duration: 0.8 }, "-=0.8")
      .to(chevronRef.current, { opacity: 1, y: 0, duration: 0.8 }, "-=0.5");

    return () => {
      tl.kill();
    };
  }, []);

  const scrollToNextSection = () => {
    const heroHeight =
      document.getElementById("hero-section")?.offsetHeight ||
      window.innerHeight;
    window.scrollTo({
      top: heroHeight,
      behavior: "smooth",
    });
  };

  return (
    <section
      id="hero-section"
      className="flex min-h-[100dvh] w-full flex-col justify-end gap-32 overflow-x-hidden p-3"
    >
      <div
        ref={heroRef}
        className="relative flex flex-1 flex-col justify-end gap-32 rounded-3xl bg-cover bg-center bg-no-repeat px-4 ph:px-24 md:px-48 lg:px-72 xl:px-96"
        style={{ backgroundImage: `url(${background.src})` }}
      >
        <div
          ref={overlayRef}
          className="absolute inset-0 rounded-3xl bg-black opacity-30"
        ></div>

        <div
          ref={contentRef}
          className="relative z-10 mb-16 flex flex-col items-center"
        >
          <img
            src="/images/logo.svg"
            alt="Les Chambres d'Anne"
            className="mb-6 h-16 brightness-0 invert"
          />
          <h1 className="text-center text-4xl font-light tracking-wider text-white md:text-5xl lg:text-6xl">
            <span className="font-semibold">{t("hero.title")}</span>
          </h1>
          <p className="mt-4 max-w-2xl text-center text-xl font-light text-white md:text-2xl">
            {t("hero.subtitle")}
          </p>

          <div
            ref={chevronRef}
            className="mt-[20dvh] cursor-pointer md:mt-12"
            onClick={scrollToNextSection}
          >
            <div className="h-12 w-12 text-white/80 transition-colors hover:text-white">
              <div className="animate-gentle-bounce">
                <SystemuiChevronDownCircle className="h-full w-full" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
