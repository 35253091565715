"use client";

import Image from "next/image";
import { useState, useRef, useEffect, useCallback } from "react";
import { reviews } from "@/data/reviews";
import { motion, AnimatePresence } from "framer-motion";
import { useI18n } from "@/i18n/client";

const StarRating = ({ rating }: { rating: number }) => {
  return (
    <div className="flex">
      {[...Array(rating)].map((_, i) => (
        <svg
          key={i}
          className="h-5 w-5 text-amber-400"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
        </svg>
      ))}
    </div>
  );
};

export const ReviewsSection = () => {
  const { t, locale } = useI18n();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const nextReview = useCallback(() => {
    if (isAnimating) return;
    setIsAnimating(true);
    setActiveIndex((prev) => (prev + 1) % reviews.length);
    setTimeout(() => setIsAnimating(false), 500);
  }, [isAnimating]);

  // Fonction pour passer à l'avis précédent
  const prevReview = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setActiveIndex((prev) => (prev - 1 + reviews.length) % reviews.length);
    setTimeout(() => setIsAnimating(false), 500);
  };

  // Défilement automatique des avis
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      nextReview();
    }, 6000);

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [nextReview]);

  // Arrêter le défilement automatique lorsque l'utilisateur interagit
  const handleInteraction = () => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      nextReview();
    }, 6000);
  };

  // Obtenir la citation dans la langue appropriée
  const getQuote = (index: number) => {
    return locale === "fr" ? reviews[index].quote : reviews[index].quoteEn;
  };

  return (
    <section
      id="avis"
      className="bg-primary px-4 py-20 ph:px-24 md:px-48 lg:px-72 xl:px-96"
    >
      <div className="flex flex-col items-start gap-16 md:items-center">
        {/* Titre de la section */}
        <div className="flex flex-col items-start gap-2 text-center md:items-center">
          <h2 className="text-3xl font-light tracking-wider xs:text-4xl ph:text-5xl">
            {t("reviews.title")}
          </h2>
          <p className="text-left text-xl font-light md:text-center">
            {t("reviews.subtitle")}
          </p>
        </div>

        {/* Carrousel de témoignages */}
        <div className="w-full max-w-4xl">
          <div className="relative overflow-hidden rounded-2xl bg-white p-6 shadow-sm md:p-10">
            <AnimatePresence mode="wait">
              <motion.div
                key={activeIndex}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
                className="flex flex-col gap-6"
              >
                {/* En-tête avec photo et nom */}
                <div className="flex items-center gap-4">
                  {/* Image du client (taille réduite) */}
                  <div className="relative h-16 w-16 shrink-0 overflow-hidden rounded-full border-2 border-[#4A7C59]/20">
                    <Image
                      src={reviews[activeIndex].image}
                      alt={reviews[activeIndex].name}
                      fill
                      className="object-cover"
                    />
                  </div>

                  <div>
                    <p className="text-lg font-medium text-[#4A7C59]">
                      {reviews[activeIndex].name}
                    </p>
                    <div className="mt-1">
                      <StarRating rating={reviews[activeIndex].stars} />
                    </div>
                  </div>
                </div>

                {/* Contenu du témoignage */}
                <div className="mb-10 flex h-[250px] flex-col justify-center xs:h-[200px] ph:h-[160px] sm:h-[130px] lg:h-[100px]">
                  <p className="flex h-full items-center text-lg font-light italic leading-relaxed text-gray-700">
                    &ldquo;{getQuote(activeIndex)}&rdquo;
                  </p>
                </div>
              </motion.div>
            </AnimatePresence>

            {/* Boutons de navigation */}
            <div className="absolute bottom-6 right-6 flex gap-2 md:bottom-10 md:right-10">
              <button
                onClick={() => {
                  prevReview();
                  handleInteraction();
                }}
                className="flex h-10 w-10 items-center justify-center rounded-full border border-gray-200 bg-white text-[#4A7C59] transition-colors hover:bg-[#4A7C59] hover:text-white"
                aria-label={t("reviews.previousReview")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>
              <button
                onClick={() => {
                  nextReview();
                  handleInteraction();
                }}
                className="flex h-10 w-10 items-center justify-center rounded-full border border-gray-200 bg-white text-[#4A7C59] transition-colors hover:bg-[#4A7C59] hover:text-white"
                aria-label={t("reviews.nextReview")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </button>
            </div>
          </div>

          {/* Indicateurs de pagination */}
          <div className="mt-6 flex justify-center gap-2">
            {reviews.map((_, index) => (
              <button
                key={index}
                onClick={() => {
                  setActiveIndex(index);
                  handleInteraction();
                }}
                className={`h-2 w-2 rounded-full transition-all ${
                  activeIndex === index
                    ? "w-6 bg-[#4A7C59]"
                    : "bg-gray-300 hover:bg-gray-400"
                }`}
                aria-label={`${t("reviews.viewReview")} ${index + 1}`}
              />
            ))}
          </div>

          {/* Boutons CTA pour les avis */}
          <div className="mt-10 flex flex-row items-center justify-center gap-2 sm:gap-4">
            <a
              href="https://g.page/r/CU2Ws2loq-yyEBM/review"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-1 rounded-full bg-tertiary px-3 py-2 text-xs text-white transition-all hover:bg-[#3d6549] hover:shadow-md sm:gap-2 sm:px-6 sm:py-3 sm:text-base"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 sm:h-5 sm:w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
              {t("reviews.leaveReview")}
            </a>
            <a
              href="https://www.google.com/search?sca_esv=3254d03019e4d8d1&rlz=1C5CHFA_enFR1131FR1131&sxsrf=AHTn8zqHyFX1EmTV7iCLcnCWYrKBJsK--A:1740661160197&uds=ABqPDvztZD_Nu18FR6tNPw2cK_RR_nOjWOfCgfo1dtdxBxE46ESxJJ4ue0T8NMUEBPngXcYc8qvGw0Zbs4m1YZkP2p0bQQUzg91veW2PjuSjHllErrfK5C_D3IYtCtz9flUNRdWtvEoEDDrE3urbVLjfCr0fiZ2ZpfpxFZgsMCmx8B7AoTxYe0E&si=APYL9bs7Hg2KMLB-4tSoTdxuOx8BdRvHbByC_AuVpNyh0x2KzR7BhTcWqesmeBrf3ENhzejVwMRFZQjDWDzRHA9CU332ZGq3mVx9BKyqVTU-L3yjKtn2F_I%3D&q=LES+CHAMBRES+D%27ANNE+Avis&sa=X&sqi=2&ved=2ahUKEwjJ04KR9OOLAxVRQ6QEHRJAG3UQ3PALegQIRRAF&biw=1470&bih=797&dpr=2"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-1 rounded-full border border-[#4A7C59] px-3 py-2 text-xs text-[#4A7C59] transition-all hover:bg-[#4A7C59]/10 sm:gap-2 sm:px-6 sm:py-3 sm:text-base"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 sm:h-5 sm:w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                />
              </svg>
              {t("reviews.viewAllReviews")}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReviewsSection;
