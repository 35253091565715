"use client";

import { Icon } from "@iconify/react";
import { useI18n } from "@/i18n/client";

export const ServicesSection = () => {
  const { t } = useI18n();

  return (
    <section
      id="services"
      className="flex flex-col items-center gap-16 bg-secondary px-4 py-20 text-white ph:px-24 md:px-48 lg:px-72 xl:px-96"
    >
      <div className="flex flex-col items-center gap-4">
        <h2 className="text-center text-3xl font-light tracking-wider xs:text-4xl ph:text-5xl">
          {t("services.title")}
        </h2>
        <p className="text-center text-xl">{t("services.subtitle")}</p>
      </div>

      <div className="grid w-full grid-cols-1 justify-items-center gap-16 ph:grid-cols-2 md:grid-cols-2 lg:grid-cols-3">
        {/* WiFi */}
        <div className="mx-auto flex max-w-xs flex-col items-center text-center ph:mx-0">
          <div className="mb-4 flex h-16 w-16 items-center justify-center rounded-full border border-[#E8DDB5]">
            <Icon
              icon="ph:wifi-high-light"
              className="h-8 w-8 text-[#E8DDB5]"
            />
          </div>
          <h3 className="mb-3 text-2xl">{t("services.wifi.title")}</h3>
          <p className="text-xl">{t("services.wifi.description")}</p>
        </div>

        {/* Parking privé */}
        <div className="mx-auto flex max-w-xs flex-col items-center text-center ph:mx-0">
          <div className="mb-4 flex h-16 w-16 items-center justify-center rounded-full border border-[#E8DDB5]">
            <Icon icon="ph:car-light" className="h-8 w-8 text-[#E8DDB5]" />
          </div>
          <h3 className="mb-3 text-2xl">{t("services.parking.title")}</h3>
          <p className="font-garamond text-xl">
            {t("services.parking.description")}
          </p>
        </div>

        {/* Espace extérieur */}
        <div className="mx-auto flex max-w-xs flex-col items-center text-center ph:mx-0">
          <div className="mb-4 flex h-16 w-16 items-center justify-center rounded-full border border-[#E8DDB5]">
            <Icon icon="ph:tree-light" className="h-8 w-8 text-[#E8DDB5]" />
          </div>
          <h3 className="mb-3 text-2xl">{t("services.outdoor.title")}</h3>
          <p className="text-xl">{t("services.outdoor.description")}</p>
        </div>

        {/* Petit déjeuner inclus */}
        <div className="mx-auto flex max-w-xs flex-col items-center text-center ph:mx-0">
          <div className="mb-4 flex h-16 w-16 items-center justify-center rounded-full border border-[#E8DDB5]">
            <Icon icon="ph:coffee-light" className="h-8 w-8 text-[#E8DDB5]" />
          </div>
          <h3 className="mb-3 text-2xl">{t("services.breakfast.title")}</h3>
          <p className="text-xl">{t("services.breakfast.description")}</p>
        </div>

        {/* Linge de maison */}
        <div className="mx-auto flex max-w-xs flex-col items-center text-center ph:mx-0">
          <div className="mb-4 flex h-16 w-16 items-center justify-center rounded-full border border-[#E8DDB5]">
            <Icon icon="ph:bed-light" className="h-8 w-8 text-[#E8DDB5]" />
          </div>
          <h3 className="mb-3 text-2xl">{t("services.linen.title")}</h3>
          <p className="text-xl">{t("services.linen.description")}</p>
        </div>

        {/* Non-fumeur */}
        <div className="mx-auto flex max-w-xs flex-col items-center text-center ph:mx-0">
          <div className="mb-4 flex h-16 w-16 items-center justify-center rounded-full border border-[#E8DDB5]">
            <Icon
              icon="ph:cigarette-slash-thin"
              className="h-8 w-8 text-[#E8DDB5]"
            />
          </div>
          <h3 className="mb-3 text-2xl">{t("services.nonsmoking.title")}</h3>
          <p className="text-xl">{t("services.nonsmoking.description")}</p>
        </div>
      </div>
    </section>
  );
};
