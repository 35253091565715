"use client";

import useScrollRestoration from "@/utils/hooks/useScrollRestoration";
import { HeroSection } from "@/(landing-page)/sections/HeroSection";
import { ContactSection } from "@/(landing-page)/sections/ContactSection";
import { ServicesSection } from "@/(landing-page)/sections/ServicesSection";
import { IntroSection } from "@/(landing-page)/sections/IntroSection";
import { ExtraSection } from "@/(landing-page)/sections/ExtraSection";
import { RoomsSection } from "@/(landing-page)/sections/RoomsSection";
import { PlaceSection } from "@/(landing-page)/sections/PlaceSection";
import { ReviewsSection } from "@/(landing-page)/sections/ReviewsSection";

export default function HomePage() {
  useScrollRestoration();
  return (
    <main className="flex min-h-screen flex-col overflow-x-hidden">
      <HeroSection />
      <IntroSection />
      <ServicesSection />
      <ExtraSection />
      <RoomsSection />
      <PlaceSection />
      <ContactSection />
      <ReviewsSection />
    </main>
  );
}
